<template>
  <section
    v-if="product != undefined"
    class="product"
    :id="'product' + product.id"
  >
    <div class="wrapper">
      <h2 v-html="product.name"></h2>
      <div class="product-left">
        <v-card>
          <div class="product-bg">
            <div class="product-links">
              <a :href="'https://loop.linqode.com/images/'+product.pdf" download v-if="product.pdf != 'null' && product.pdf != null && product.pdf != ''"
                >download PDF</a
              >
              <a
                :href="product.url"
                target="_blank"
                v-if="product.url != 'null' && product.pdf != null && product.pdf != ''"
                >Saber Mais</a
              >
            </div>
            <div class="product-images">
              <v-carousel
                hide-delimiters
                height="300"
                :show-arrows="images.length > 1"
              >
                <v-carousel-item v-for="(image, i) in images" :key="i">
                  <img :src="img_url + image" />
                </v-carousel-item>
              </v-carousel>
            </div>
            <!-- <img alt="logo-vega" src="images/{{img}}"> -->
          </div>
        </v-card>
      </div>
      <div class="product-right">
        <ul>
          <li>
            <p class="label bold">Aplicação</p>
            <p v-html="product.app_name"></p>
          </li>
          <li>
            <p class="label bold">Função</p>
            <p v-html="product.funcao_name"></p>
          </li>
          <li>
            <p class="label bold">Marca</p>
            <p v-html="product.marca_name"></p>
          </li>
          <li>
            <div class="description" v-html="product.description"></div>
          </li>
        </ul>
        <div class="form">
          <h3 class="loop-red">Pedido de Informações</h3>
          <h3>Preencha com os seus dados e entraremos em contacto</h3>
          <!-- <div class="inputGroup">
            <label>Email</label>
            <input type="email" id="email" tarefa="" required />
          </div>
          <div class="inputGroup">
            <label>Telefone</label>
            <input type="tel" id="phone" tarefa="" required />
          </div>
          <div class="inputGroup">
            <label>Mensagem</label>
            <textarea id="mensage" rows="4" tarefa="" required></textarea>
          </div> -->
          <v-row>
            <v-col cols="6" class="pa-2">
              <v-text-field
                label="Email"
                v-model="form.email"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-2">
              <v-text-field
                label="Telefone"
                v-model="form.phone"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-textarea
                label="Mensagem"
                v-model="form.msg"
                rows="3"
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="form.checkbox">
                <template v-slot:label>
                  <div>Declaro que li e aceito a Política de Protecção de Dados.</div>
                </template>
              </v-checkbox>
            </v-col>
            <p class="error-msg">{{ errMsg }}</p>
          </v-row>
          <a @click="submeter()" class="button-c">Enviar</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import axios from "axios";

export default {
  mixins: [computedMixins],
  props: ["all_products"],
  data() {
    return {
      form: {
        email: "",
        phone: "",
        msg: "",
        checkbox: false,
      },
      email: "",
        phone: "",
        msg: "",
        checkbox: false,
      errMsg: "",
    };
  },
  computed: {
    product() {
      let products = this.all_products;
      let product = {};
      let id = this.$store.state.selectedProductId;

      if (id > 0) {
        product = products.filter(function (item) {
          return item.id == id;
        });
      }
      if(typeof product[0] != 'undefined' && product[0].url.slice(0,4) != 'http'){
        product[0].url = 'https://' + product[0].url
      }
      return product[0];
    },
    images() {
      let temp = [];
      let prod = this.product;
      let images = [];

      temp.push(prod.img, prod.img2, prod.img3, prod.img4, prod.img5);

      images = temp.filter((val) => val != null);

      return images;
    },
  },
  methods: {
    // async submeter() {
    //   event.preventDefault()


    //   this.errMsg = ""

    //   let errorMsgs = {
    //       email: "Introduza o seu email",
    //       msg: "Escreva a sua mensagem",
    //       checkbox:"Para enviar a sua mensagem terá que declarar que leu e aceitou a Política de Protecção de Dados",
    //       success: "A sua mensagem foi enviada com sucesso! Será contactado brevemente",
    //       fail: "Houve um erro no envio da sua mensagem. Por favor tente novamente mais tarde ou entre em contacto através de um dos canais alternativos",
    //       sending: "A enviar..."
    //   } 

  
    //   if(this.form.name == "") {
    //     this.errMsg = errorMsgs.name
    //   }
    //   else if(this.form.email == "") {
    //     this.errMsg = errorMsgs.email
    //   }
    //   else if(this.form.msg == "") {
    //     this.errMsg = errorMsgs.msg
    //   }
    //   else if(this.form.checkbox == false) {
    //     this.errMsg = errorMsgs.checkbox
    //   }

    //   else {
    //     this.errMsg = errorMsgs.sending

    //     let mensagem = `
    //       <p><b>Produto: </b>${this.product.name}</p>
    //       <p><b>Email: </b>${this.form.email}</p>
    //       <p><b>Telefone: </b>${this.form.phone}</p>
    //       <p><b>Mensagem: </b><br>${this.form.msg}</p>
    //     `

    //     let fd = new FormData()

    //     fd.append('mensagem', mensagem)

    //     var myHeaders = new Headers();

    //     var myInit = { 
    //       method: 'POST',
    //       headers: myHeaders,
    //       body: fd
    //     };

    //     let app = this

    //     fetch('/base_mailer/', myInit)
    //     .then(async function(response) {
    //       let res = await response.text()

    //       console.log(res)

    //       if(res == "OK") {
    //         app.errMsg = errorMsgs.success
    //       }
    //       else {
    //         app.errMsg = errorMsgs.fail
    //       }
    //     })

    //   }
    // }
    async submeter() {
      event.preventDefault()
      this.errorFontColor = ""
      this.errMsg = "A enviar"

      if(this.phone == ""){
        this.phone = "Não enviou número de telefone"
      }

      let dados = {
        assunto: "Pedido de Informações sobre o produto " + this.product.name,
        email: this.form.email,
        msg: this.form.msg,
        checkbox: this.form.checkbox,
        phone: this.form.phone,
        form: "Produto: " + this.product.name
      };

      if(dados.phone == ""){
        dados.phone = "Não enviou número de telefone"
      }

    if (dados.email == "") {
        this.errorFontColor = "red"
        this.errMsg = "Introduza o seu email";
        return;
      } else if (dados.msg == "") {
        this.errorFontColor = "red"
        this.errMsg = "Escreva a sua mensagem";
        return;
      } else if (!dados.checkbox) {
        this.errorFontColor = "red"
        this.errMsg = "Para enviar a sua mensagem terá que declarar que leu e aceitou a Política de Protecção de Dados";
        return;
      } else {
        try {
          const response = await axios.post(
            "https://loop.linqode.com/api/public/api/contacts/send",
            dados
          );

          if(response.data.resultado == "OK") {
            this.errorFontColor = ""
            this.errMsg = "A sua mensagem foi enviada com sucesso!";
          }
          else {
            this.errorFontColor = ""
            this.errMsg = response.data.details;
          }
        } catch (err) {
          if (err.response.status == 401) {
            this.errorFontColor = "red"
            this.errMsg = err.response.data.details;
          } else if(err.response.status == 204) {
            this.errorFontColor = ""
            this.errMsg = err.response.data.details;
          }
          else {
            this.errorFontColor = "red"
            this.errMsg = "Alguma coisa falhou. Por favor tente mais tarde";
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.product {
  .wrapper {
    min-height: 50px;
    padding: 60px 0;
    @include responsive(0, 512px) {
      padding: 40px 0;
    }
    h2 {
      margin-bottom: 50px;
    }
    .product-left {
      width: 45%;
      display: inline-block;
      margin-right: 5%;
      vertical-align: top;
      @include responsive(0, 768px) {
        width: 100%;
      }
      .product-bg {
        -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
        -moz-box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
        box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
        height: 500px;
        text-align: center;
        position: relative;
        @include responsive(0, 512px) {
          margin-bottom: 30px;
          height: 400px;
        }
        .product-images {
          height: 300px;
          padding-top: 60px;
          @include responsive(0, 512px){
            padding-top: 10px;
          }
          img {
            max-height: 100%;
            max-width: 70%;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
          }
        }
        .product-links {
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
          a {
            font-size: 14px;
            font-weight: $bold;
            text-decoration: none;
            color: $color-black;
            display: block;
            font-family: $font2;
            margin-bottom: 5px;
            position: relative;
            bottom: 5px;
          }
        }
      }
    }
    .product-right {
      width: 50%;
      display: inline-block;
      @include responsive(0, 768px) {
        width: 100%;
      }
      ul {
        li {
          vertical-align: top;
          div {
            display: inline-block;
            &.label {
              width: 100px;
            }
            &.description {
              div {
                @include tp-font-size(14px, 14px, 14px, 13px, 13px, 13px, 13px);
                @include tp-line-height(
                  20px,
                  20px,
                  20px,
                  18px,
                  18px,
                  18px,
                  18px
                );
                //@include tp-margin-bottom ($margin-bottom,$w1440,$w1024,$w900,$w768,$w512,$w380);
                font-family: $font2;
                font-weight: $light;
                color: $color-black;
                text-align: left;
                text-transform: none;
                display: inline-block;
              }
              @include tp-font-size(14px, 14px, 14px, 13px, 13px, 13px, 13px);
              @include tp-line-height(20px, 20px, 20px, 18px, 18px, 18px, 18px);
              //@include tp-margin-bottom ($margin-bottom,$w1440,$w1024,$w900,$w768,$w512,$w380);
              font-family: $font2;
              font-weight: $light;
              color: $color-black;
              text-align: left;
              text-transform: none;
              display: inline-block;
              margin-top: 20px;
              width: 80%;
              margin-bottom: 30px;
              @include responsive(0, 768px) {
                width: 100%;
              }
            }
            div {
              &.label {
                width: 100px;
              }
              &.description {
                div {
                  @include tp-font-size(
                    14px,
                    14px,
                    14px,
                    13px,
                    13px,
                    13px,
                    13px
                  );
                  @include tp-line-height(
                    20px,
                    20px,
                    20px,
                    18px,
                    18px,
                    18px,
                    18px
                  );
                  //@include tp-margin-bottom ($margin-bottom,$w1440,$w1024,$w900,$w768,$w512,$w380);
                  font-family: $font2;
                  font-weight: $light;
                  color: $color-black;
                  text-align: left;
                  text-transform: none;
                  display: inline-block;
                }
                @include tp-font-size(14px, 14px, 14px, 13px, 13px, 13px, 13px);
                @include tp-line-height(
                  20px,
                  20px,
                  20px,
                  18px,
                  18px,
                  18px,
                  18px
                );
                //@include tp-margin-bottom ($margin-bottom,$w1440,$w1024,$w900,$w768,$w512,$w380);
                font-family: $font2;
                font-weight: $light;
                color: $color-black;
                text-align: left;
                text-transform: none;
                display: inline-block;
                margin-top: 20px;
                width: 80%;
                margin-bottom: 30px;
                @include responsive(0, 768px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .form {
      background-color: white;
      width: 100%;
      // -webkit-box-shadow: 2px 2px 5px 0px rgba(153,150,153,0.8);
      // -moz-box-shadow: 2px 2px 5px 0px rgba(153,150,153,0.8);
      // box-shadow: 2px 2px 5px 0px rgba(153,150,153,0.8);
      margin-top: 10px;
      font-size: 14px;
      h3 {
        text-align: left;
        font-weight: $semi-bold;
        &.loop-red {
          color: $red;
          text-transform: uppercase;
          margin-bottom: 0px;
        }
      }
      .inputGroup {
        margin: 10px 0;
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 0 20px;
      
        @include responsive(0, 768px) {
          width: 100%;
        }
      }
      .button-c {
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  }
}

.v-input--checkbox{
  div{
    font-size: 12px;
  }
}

.error-msg {
  font-size: 12px;
  color: $red;
  font-family: $font1;
  font-weight: 500;
}
</style>