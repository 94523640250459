<template>
  <div class="main-container">
    <Search :all_products="all_products"/>
    <Product :all_products="all_products" />
  </div>
</template>

<script>
import Search from "@/components/produtos";
import Product from "@/components/product";
import computedMixins from "@/mixins/computed";

export default {
  mixins: [computedMixins],
  props:["all_products"],
  components: {
    Search,
    Product,
  },
  data() {
    return {
    };
  },
  methods: {
    
  },

};
</script>

<style lang="scss" scoped>

</style>